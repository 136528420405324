import React, { useState } from "react"
import styles from "../../../styles/solutions.module.scss"
import cblockStyles from "../../../styles/commons/cblock.module.scss"
import FsLightbox from "fslightbox-react"
import Layout from "../../../components/layout/layout"
import StartDemo from "../../../components/start-demo/start-demo"
import SEO from "../../../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import withAppContext from "../../../contexts/app.context"
import UseCaseOverview from "../../../components/use-case-overview/use-case-overview"
import { Helper } from "../../../utils/helper"
import { ENV_CONSOLE_BASE_URL } from "../../../utils/secrets"
import EnsHeroAnim from "../../../components/animated-graphs/ens-hero/ens-hero-anim"
import TopFeatures from "../../../components/top-features/top-features"
import ComparisonTable from "../../../components/comparison-table/comparison-table"
import CascadeVideo from "../../../components/cascade-video/cascade-video"
import PlaySvg from "../../../components/svg/play"
import ArrowSvg from "../../../components/svg/arrow"

const EdgeToCloudPage = () => {
  const queryResult = useStaticQuery(graphql`
    {
      seo: markdownRemark(
        fields: {
          relativePath: { eq: "solutions__edge-networking-security" }
          category: { eq: "seo" }
        }
      ) {
        frontmatter {
          metaTitle
          metaDescription
          metaKeywordList
          metaImageName
          metaTwImageName
        }
      }
    }
  `)

  const {
    seo: {
      frontmatter: {
        metaTitle,
        metaDescription,
        metaKeywordList,
        metaImageName,
        metaTwImageName,
      },
    },
  } = queryResult

  const demoLink = "https://player.vimeo.com/video/448789268?autoplay=1"
  const overviewLink = "https://player.vimeo.com/video/409054284?autoplay=1"

  const [isVideoOpened, setVideoOpened] = useState(false)
  const [currentVideoUrl, setCurrentVideoUrl] = useState(overviewLink)

  const openSelectedVideo = () => {
    Helper.triggerGtagVideo(currentVideoUrl)
    setCurrentVideoUrl(overviewLink)
    setVideoOpened(!isVideoOpened)
  }

  const openDemoVideo = () => {
    Helper.triggerGtagVideo(currentVideoUrl)
    setCurrentVideoUrl(demoLink)
    setVideoOpened(!isVideoOpened)
  }

  const [selectedTopFeatIndex, setselectedTopFeatIndex] = useState(1)

  return (
    <Layout>
      <SEO
        title={metaTitle}
        pageUrl={"/solutions/edge/edge-networking-and-security"}
        description={metaDescription}
        keywords={metaKeywordList}
        imageName={!!metaImageName ? `/previews/${metaImageName}` : undefined}
        twImageName={
          !!metaTwImageName ? `/previews/${metaTwImageName}` : undefined
        }
      />

      {/* Hero Block */}
      <section className={styles.header}>
        <div className={styles.header__content}>
          <div className="container">
            <div className="row">
              <div className={`col-lg-7`}>
                <h1 className={styles.header__title}>
                  Edge Networking & Security
                </h1>
                <p className={styles.header__description}>
                  Next generation networking and security service for a large
                  number of edge sites — our consolidated software stack and
                  global network reduces TCO and operational burden for NetOps
                  and DevOps.
                </p>
                <div className={styles.header__cta}>
                  <a
                    aria-label="Start for Free"
                    href={`${ENV_CONSOLE_BASE_URL}/signup/start`}
                    className={"btn btn-primary " + styles.header__btn}>
                    Start for Free
                  </a>
                  <button
                    onClick={openDemoVideo}
                    className={`btn btn-primary ml-4 ${styles.header__btn} ${styles.header__btnPlay}`}>
                    <PlaySvg />
                    Watch Demo
                  </button>
                </div>
                {/* <div className={`pt-4 ${styles.header__doc}`}>
                  <p>
                    <a 
                      aria-label="View demo documentation" 
                      className={styles.header__link} 
                      href="/">
                      View demo documentation
                      <ArrowSvg />
                    </a>
                  </p>
                </div> */}
              </div>
            </div>
          </div>
          <div className={styles.header__imgwrapper}>
            <button
              className={styles.header__imgbtn}
              onClick={openSelectedVideo}>
              <PlaySvg />
            </button>
            <EnsHeroAnim />
          </div>
        </div>
      </section>

      {/* Benefits */}
      <section className={cblockStyles.cblock}>
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>
              Benefits of Volterra Edge <br />
              Networking & Security
            </h2>
          </div>
          <div className={cblockStyles.cblock__body}>
            <TopFeatures
              props={{
                features: [
                  {
                    title: "Dramatically simplified operations",
                    desc:
                      "VoltMesh’s consolidated L3-L7 network + security stack reduces the burden of policy + observability while our global backbone removes the need to build and maintain a high performance edge to cloud network.",
                    imgUrl: "/img/solutions/operational-simplification.svg",
                    imgAlt:
                      "Auto updating cloud and app infrastructure illustration",
                  },
                  {
                    title: "Zero-trust secure access",
                    desc:
                      "Our distributed proxy-based architecture dramatically simplifies security of your cloud and edge resources by removing the need to control access using routing and security policies. Access your cloud-based apps without network connectivity to the cloud.",
                    imgUrl: "/img/solutions/maximum-security.svg",
                    imgAlt: "Firewall protecting cloud deployment illustration",
                  },
                  {
                    title: "Dramatic cost savings",
                    desc:
                      "Reduce TCO by 70% with our SaaS-based service, usage-based model, and automated lifecycle management. Our high performance network delivers dramatically lower costs to connect global edge sites to multi-cloud.",
                    imgUrl: "/img/solutions/significant-cost-savings.svg",
                    imgAlt:
                      "Integrated functions create costs savings illustration",
                  },
                  {
                    title: "Seamless global expansion",
                    desc:
                      "Quickly launch service in new geographies by leveraging our global platform. Our distributed control plane will automatically enforce your intent as soon as you deploy a new site in any location and provide end-to-end visibility.",
                    imgUrl: "/img/solutions/seamless-global-expansion.svg",
                    imgAlt: "Quick global app deployment illustration",
                  },
                ],
              }}
            />
          </div>
        </div>
      </section>

      {/* Comparison Table */}
      <section className={cblockStyles.cblock}>
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>
              VoltMesh vs. Other Edge Networking Solutions
            </h2>
          </div>
          <div className={cblockStyles.cblock__body}>
            <ComparisonTable
              props={{
                cols: ["Features", "Traditional", "VoltMesh"],
                rows: [
                  {
                    name: "Zero touch provisioning",
                    cells: ["Included", "Included"],
                  },
                  {
                    name: "Consolidated L3-L7 network + security service",
                    cells: ["-", "Included"],
                  },
                  {
                    name: "Zero-trust secure access",
                    cells: ["-", "Included"],
                  },
                  {
                    name: "Multi-tenancy for NetOps, DevOps, SecOps",
                    cells: ["-", "Included"],
                  },
                  {
                    name: "Unified and fleet-wide policy",
                    cells: ["-", "Included"],
                  },
                  {
                    name: "Edge to cloud connectivity",
                    cells: ["Internet/VPNs", "Global Private Backbone"],
                  },
                  {
                    name: "Lifecycle management",
                    cells: ["DIY", "SaaS"],
                  },
                ],
              }}
            />
          </div>
        </div>
      </section>

      {/* Solution Overview */}
      <UseCaseOverview
        props={{
          content: {
            description: `
            <p>VoltMesh secure edge gateway consolidates a range of networking, SD-WAN, load balancing, API gateway and security services across edge sites. VoltMesh can be deployed on our industrial-grade edge hardware, your custom hardware or virtual machines.<p>
            <p>Each edge deployment terminates at two or more Volterra points of presence (PoPs) for high-performance connectivity to your cloud, data centers and the Internet. VoltConsole provides automated software lifecycle, policy management, as well as global observability to simplify operations across edge sites.</p>`,
            diagram: {
              alt: "Network & Security diagram",
              name: "edge-networking-and-security-diagram.png",
              width: 840,
              md: {
                name: "edge-networking-and-security-diagram-md.png",
                width: 760,
              },
              sm: {
                name: "edge-networking-and-security-diagram-sm.png",
                width: 460,
              },
            },

            capabilities: {
              left: [
                "Networking, SD-WAN and security services",
                "Redundant connections to Volterra PoPs",
                "Zero-touch provisioning for edge deployments",
                "Load balancer + API gateway for edge apps",
                "High-performance edge-to-cloud connectivity",
                "Centralized policy and observability",
              ],
            },
          },
          keycapafullwidth: true,
        }}
      />

      {/* Ready in minutes */}
      <section
        className={`${cblockStyles.cblock} ${cblockStyles.cblock__bubbles__vmtlmr} ${cblockStyles.cblock__grey}`}>
        <div className={"container"}>
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>
              Securely Connect Edge to Cloud within Minutes
            </h2>
          </div>

          <div className={cblockStyles.cblock__body}>
            <div className={"row " + styles.topfeatsslider}>
              <div className="col-lg-5">
                <div className={styles.topfeatsslider__items}>
                  {/* Item 1 */}
                  <div
                    onClick={() => {
                      setselectedTopFeatIndex(1)
                    }}
                    className={
                      styles.topfeatsslider__item +
                      `${
                        selectedTopFeatIndex === 1
                          ? ` ${styles.topfeatsslider__item__active}`
                          : ""
                      }`
                    }>
                    <div className={styles.topfeatsslider__head}>
                      <span className={styles.topfeatsslider__no}>1</span>
                      <h5>
                        Zero-touch provisioning of&nbsp;
                        <span>
                          edge
                          <img
                            className={`ml-2 ${styles.topfeatsslider__caret}`}
                            src="/img/products/caret-icon.svg"
                            alt=">"
                          />
                        </span>
                      </h5>
                    </div>
                    <div className={styles.topfeatsslider__inner}>
                      <p>
                        Ship edge hardware with pre-installed VoltMesh software
                        and register edge site using VoltConsole
                      </p>
                      <a
                        href="/docs/quick-start/edge-networking-and-security#step-1-provision"
                        className={styles.topfeatsslider__link}
                        target="_blank"
                        aria-label="Step 1: Provision">
                        Read more
                        <ArrowSvg className="arrow" />
                      </a>
                      <div className={styles.topfeatsslider__mobilecontent}>
                        {selectedTopFeatIndex === 1 && (
                          <CascadeVideo
                            props={{
                              videos: [
                                {
                                  title: "UI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/461652644.sd.mp4?s=4e7d4f9321a5888eac2a8ca1a13f9f390cc5ce33&profile_id=165",
                                  videoLength: "25000",
                                },
                              ],
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Item 2 */}
                  <div
                    onClick={() => {
                      setselectedTopFeatIndex(2)
                    }}
                    className={
                      styles.topfeatsslider__item +
                      `${
                        selectedTopFeatIndex === 2
                          ? ` ${styles.topfeatsslider__item__active}`
                          : ""
                      }`
                    }>
                    <div className={styles.topfeatsslider__head}>
                      <span className={styles.topfeatsslider__no}>2</span>
                      <h5>
                        Fleet-wide&nbsp;
                        <span>
                          configurations
                          <img
                            className={styles.topfeatsslider__caret}
                            src="/img/products/caret-icon.svg"
                            alt=">"
                          />
                        </span>
                      </h5>
                    </div>
                    <div className={styles.topfeatsslider__inner}>
                      <p>
                        Create fleet-wide network configuration add site to the
                        fleet and the control plane will enforce your intent
                      </p>
                      <a
                        href="/docs/quick-start/edge-networking-and-security#step-2-define"
                        className={styles.topfeatsslider__link}
                        target="_blank"
                        aria-label="Step 2: Define">
                        Read more
                        <ArrowSvg className="arrow" />
                      </a>
                      <div className={styles.topfeatsslider__mobilecontent}>
                        {selectedTopFeatIndex === 2 && (
                          <CascadeVideo
                            props={{
                              videos: [
                                {
                                  title: "UI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/462075088.sd.mp4?s=8b5757badbac955f3e376bee42097a0464fad7af&profile_id=165",
                                  videoLength: "50000",
                                },
                              ],
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Item 3 */}
                  <div
                    onClick={() => {
                      setselectedTopFeatIndex(3)
                    }}
                    className={
                      styles.topfeatsslider__item +
                      `${
                        selectedTopFeatIndex === 3
                          ? ` ${styles.topfeatsslider__item__active}`
                          : ""
                      }`
                    }>
                    <div className={styles.topfeatsslider__head}>
                      <span className={styles.topfeatsslider__no}>3</span>
                      <h5>
                        Configure policies and private&nbsp;
                        <span>
                          access
                          <img
                            className={`ml-2 ${styles.topfeatsslider__caret}`}
                            src="/img/products/caret-icon.svg"
                            alt=">"
                          />
                        </span>
                      </h5>
                    </div>
                    <div className={styles.topfeatsslider__inner}>
                      <p>
                        Create security policies to control access to internet
                        and private access to your private apps in
                        public/private cloud
                      </p>
                      <a
                        href="/docs/quick-start/edge-networking-and-security#step-3-connect"
                        className={styles.topfeatsslider__link}
                        target="_blank"
                        aria-label="Step 3: Connect">
                        Read more
                        <ArrowSvg className="arrow" />
                      </a>
                      <div className={styles.topfeatsslider__mobilecontent}>
                        {selectedTopFeatIndex === 3 && (
                          <CascadeVideo
                            props={{
                              videos: [
                                {
                                  title: "UI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/462075163.sd.mp4?s=127a8d1044693a17c16bc5b3e33f7f3bf7b2fe8b&profile_id=165",
                                  videoLength: "54000",
                                },
                              ],
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Item 4 */}
                  <div
                    onClick={() => {
                      setselectedTopFeatIndex(4)
                    }}
                    className={
                      styles.topfeatsslider__item +
                      `${
                        selectedTopFeatIndex === 4
                          ? ` ${styles.topfeatsslider__item__active}`
                          : ""
                      }`
                    }>
                    <div className={styles.topfeatsslider__head}>
                      <span className={styles.topfeatsslider__no}>4</span>
                      <h5>
                        Start sending&nbsp;
                        <span>
                          requests
                          <img
                            className={styles.topfeatsslider__caret}
                            src="/img/products/caret-icon.svg"
                            alt=">"
                          />
                        </span>
                      </h5>
                    </div>
                    <div className={styles.topfeatsslider__inner}>
                      <p>
                        Start sending requests from users and apps and leave the
                        security and routing to VoltMesh
                      </p>
                      <div className={styles.topfeatsslider__mobilecontent}>
                        {selectedTopFeatIndex === 4 && (
                          <CascadeVideo
                            props={{
                              videos: [
                                {
                                  title: "UI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/462075384.sd.mp4?s=565b8941a93d9ffea32c36ac029102f69aaf6738&profile_id=165",
                                  videoLength: "59000",
                                },
                              ],
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className={styles.topfeatsslider__img}>
                  {selectedTopFeatIndex === 1 && (
                    <>
                      <CascadeVideo
                        props={{
                          videos: [
                            {
                              title: "UI",
                              videoUrl:
                                "https://player.vimeo.com/external/461652644.sd.mp4?s=4e7d4f9321a5888eac2a8ca1a13f9f390cc5ce33&profile_id=165",
                              videoLength: "25000",
                            },
                          ],
                        }}
                      />
                    </>
                  )}
                  {selectedTopFeatIndex === 2 && (
                    <>
                      <CascadeVideo
                        props={{
                          videos: [
                            {
                              title: "UI",
                              videoUrl:
                                "https://player.vimeo.com/external/462075088.sd.mp4?s=8b5757badbac955f3e376bee42097a0464fad7af&profile_id=165",
                              videoLength: "50000",
                            },
                          ],
                        }}
                      />
                    </>
                  )}
                  {selectedTopFeatIndex === 3 && (
                    <>
                      <CascadeVideo
                        props={{
                          videos: [
                            {
                              title: "UI",
                              videoUrl:
                                "https://player.vimeo.com/external/462075163.sd.mp4?s=127a8d1044693a17c16bc5b3e33f7f3bf7b2fe8b&profile_id=165",
                              videoLength: "54000",
                            },
                          ],
                        }}
                      />
                    </>
                  )}
                  {selectedTopFeatIndex === 4 && (
                    <>
                      <CascadeVideo
                        props={{
                          videos: [
                            {
                              title: "UI",
                              videoUrl:
                                "https://player.vimeo.com/external/462075384.sd.mp4?s=565b8941a93d9ffea32c36ac029102f69aaf6738&profile_id=165",
                              videoLength: "59000",
                            },
                          ],
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <StartDemo
        props={{
          benefits: [
            "Securely connect two cloud or edge sites for free, forever!",
          ],
          singleline: true,
        }}
      />

      <FsLightbox
        toggler={isVideoOpened}
        sources={[
          <iframe
            title="Video player"
            src={currentVideoUrl}
            width="1920px"
            height="1080px"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />,
        ]}
        key="1"
      />
    </Layout>
  )
}

export default withAppContext(EdgeToCloudPage)
