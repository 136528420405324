import styles from "./top-features.module.scss"
import React from "react"

const TopFeatures = ({ props }) => {
  const { features } = props
  const fullWidthMobileImages = props.fullWidthMobileImages

  return (
    <div className={styles.topfeatures}>
      {features &&
        features.map((feature, index) => (
          <div key={index} className={styles.topfeatures__feat}>
            <div
              className={`${styles.topfeatures__img} ${
                fullWidthMobileImages ? styles.topfeatures__imgFull : ""
              }`}>
              <img src={feature.imgUrl} alt={feature.imgAlt} />
            </div>
            <div className={styles.topfeatures__copy}>
              <div className={styles.topfeatures__inner}>
                <h3>{feature.title}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: feature.desc,
                  }}></p>
              </div>
            </div>
          </div>
        ))}
    </div>
  )
}

export default TopFeatures
