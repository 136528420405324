import React from "react"
import styles from "./use-case-overview.module.scss"
import cblockStyles from "../../styles/commons/cblock.module.scss"
import checkStyles from "../../styles/commons/circlechecks.module.scss"

const UseCaseOverview = ({ props }) => {
  const { content } = props
  let keycapafullwidth = props.keycapafullwidth

  return (
    <section className={`${cblockStyles.cblock} ${styles.overview}`}>
      <div className="container">
        <div
          className={`${cblockStyles.cblock__header} ${styles.overview__header}`}>
          <h2>Solution Overview</h2>
          <p
            className={cblockStyles.cblock__intro}
            dangerouslySetInnerHTML={{
              __html: content.description,
            }}
          />
        </div>
        <div className="col-12">
          <div className={styles.overview__diagram}>
            <picture>
              <source
                srcSet={`/img/solutions/${content.diagram.sm.name}`}
                media="(max-width: 767px)"
              />
              <source
                srcSet={`/img/solutions/${content.diagram.md.name}`}
                media="(min-width: 768px) and (max-width: 1023px)"
              />
              <img
                src={`/img/solutions/${content.diagram.name}`}
                alt={content.diagram.alt}
              />
            </picture>
          </div>
        </div>
        <h5 className={styles.overview__listTitle}>Key Capabilities</h5>
        <div
          className={`${styles.overview__listWrapper} ${
            keycapafullwidth ? styles.overview__listWrapperFull : ""
          }`}>
          <ul className={checkStyles.circlechecks}>
            {content.capabilities.left.map(capability => (
              <li key={capability} className={checkStyles.circlechecks__item}>
                <span>{capability}</span>
              </li>
            ))}
            {content.capabilities.right &&
              content.capabilities.right.map(capability => (
                <li key={capability} className={checkStyles.circlechecks__item}>
                  <span>{capability}</span>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default UseCaseOverview
